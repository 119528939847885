var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Clients")]),_c('div',[_c('div',{staticClass:"search-wrapper, col-5",staticStyle:{"margin-bottom":"16px"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-left"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search by Client Name","type":"text"},model:{value:(_vm.searchNameTerm),callback:function ($$v) {_vm.searchNameTerm=$$v},expression:"searchNameTerm"}})],1)])],1),_c('hr'),_c('div',{staticClass:"row mb-1",staticStyle:{"margin-bottom":"8px"}},[_c('div',{staticClass:"col-6"},[_c('div',[_c('div',{staticClass:"col-4 pr-0 pl-0 D-flex justify-space-arround"},[_c('b-button',{staticStyle:{"justify-content":"space-around","display":"flex","border-radius":"0"},attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.$router.push('/clients/add')}}},[_c('span',[_vm._v("+ Add Client")])])],1)])]),_c('div',{staticClass:"col-3 center-justified"},[_vm._v(" View All Statuses "),_c('toggle-button',{on:{"change":function($event){_vm.activeClients = $event.value}}})],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-right"},[_c('label',{staticClass:"mr-1"}),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Filter Clients","type":"text"},model:{value:(_vm.searchFilterTerm),callback:function ($$v) {_vm.searchFilterTerm=$$v},expression:"searchFilterTerm"}})],1)])],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"search-options":{
        enabled: false,
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'Name')?_c('span',{staticClass:"text-nowrap"},[_c('router-link',{attrs:{"to":'/clients/view/' + props.row.ClientID}},[_vm._v(_vm._s(props.row.Name))])],1):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',[_c('router-link',{attrs:{"to":'/clients/view/' + props.row.ClientID}},[_vm._v("View Client")])],1)],1),_c('b-dropdown-item',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_c('router-link',{attrs:{"to":'/clients/edit/' + props.row.ClientID}},[_vm._v("Edit Client")])],1)],1),(false)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteClientWithPrompt(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing "+_vm._s(_vm.displayStartIndex)+" - "+_vm._s(_vm.displayEndIndex)+" of "+_vm._s(_vm.totalResultCount)+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['100', '150', '200']},on:{"input":function (value) {
                  props.perPageChanged({ currentPerPage: value });
                  _vm.onPageSizeChanged({ currentPerPage: value });
                }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("per page")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":_vm.totalResultCount,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); },"page-click":_vm.onPageChanged},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]}}])})],1),_c('b-modal',{attrs:{"id":"modal-delete-client","ok-only":"","ok-variant":"danger","ok-title":"Delete Client","modal-class":"modal-danger","centered":"","title":"Confirm Delete Client"},on:{"ok":function($event){return _vm.confirmDeleteClient()}}},[(_vm.clientToDelete !== null)?_c('div',[_vm._v(" Are you sure you want to delete client "+_vm._s(_vm.clientToDelete.Name)+" "+_vm._s(_vm.clientToDelete.LastName)+" ("+_vm._s(_vm.clientToDelete.EMail)+")? This cannot be undone. ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }